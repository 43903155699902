import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BusinessUnits } from 'src/app/data/business-units';
import { Company } from 'src/app/data/company';
import { FormType } from 'src/app/data/form-type';
import { InfoRequest } from 'src/app/data/info-request';
import { Request } from '../../data/request';
import { Field } from 'src/app/data/field';
import { CompanyField } from 'src/app/data/company-field';
import { IfStmt } from '@angular/compiler';


@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})
export class BasicInfoComponent implements OnInit, AfterViewInit {

  showReminders: boolean = false;
  categories: Array<FormType>;
  companies: Array<Company>;
  fieldList: Array<Array<Field>>;
  businessUnits: Array<Array<BusinessUnits>>;
  request: Request;

  form: FormGroup;
  @Input('reminder') reminder: boolean;
  @Input('infoRequest') infoRequest: InfoRequest;
  @Input('fields') fields: [[Field[]], [CompanyField[]]];
  @Output() formTypeEvent = new EventEmitter<string>();
  @Output() fieldEvent = new EventEmitter<string>();


  constructor(private fb: FormBuilder) {

  }

  ngOnInit() {
    this.categories = this.fillCategories();
    this.companies = this.fillCompany();
    this.businessUnits = this.fillBUCategory();
    this.fieldList = this.fillFields();
    this.request = this.infoRequest.request;
  

    this.form = this.buildFormGroup();

    
    if (this.reminder) {
      this.showReminders = true;
    }

  }

  fillCategories(): FormType[] {
    let array: Array<FormType> = [];
    this.infoRequest.formtypes.forEach(formtype => {
      array.push(formtype);
    });
    return array;
  }
  fillFields(): Array<Array<Field>> {
    let array: Array<Array<Field>> = [];
    array.push([]);
    this.companies.forEach(company => {
      array.push([]);
    });

    this.companies.forEach(company => {
      let array2: Array<CompanyField> = this.fields[1][0].filter(
        cf => cf.company_id == company.company_id);



      var array3: Array<Field> = [];
      array2.forEach(cfield => {
        array3.push(this.fields[0][0].find(f => {
          return f.field_id === cfield.field_id
        }))
        array.splice(cfield.company_id, 0, array3);

      });


    })
    
    return array;
  }

  fillBUCategory(): Array<Array<BusinessUnits>> {
    let array: Array<Array<BusinessUnits>> = [];
    array.push([]);
    this.companies.forEach(company => {
      array.push([]);
    })
    this.companies.forEach(company => {
      let array2: Array<BusinessUnits> = this.infoRequest.businessunits.filter(
        unit => unit.company_company_id == company.company_id);
      array.splice(company.company_id, 0, array2);
    })
    return array;
  }

  fillCompany(): Company[] {
    let array: Array<Company> = [];
    this.infoRequest.companies.forEach(company => {
      array.push(company);
    })
    return array;
  }



  ngAfterViewInit(): void {
  }

  buildFormGroup() {
    
    let form = this.fb.group({
      categories: this.fb.control(this.request.formtype_request_type_id, Validators.required),
      companies: this.fb.control(this.request.business_unit.company_company_id, Validators.required),
      businessUnits: this.fb.control(this.request.business_unit.business_unit_id),
      firmName: this.fb.control(this.request.vendor_name, Validators.required),
      fields: this.fb.control(this.request.field_id,),
      vendorEmail: this.fb.control(this.request.contact_address, [Validators.required]), //, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
      inCodex: this.fb.control(this.request.vendor_number != (null || undefined)),
      vendorNumber: this.fb.control(this.request.vendor_number),
      reminders: this.fb.control(!!+this.request.send_reminders)
    })
    return form;
  }


  buildFromdata(formData?: FormData) {
    if (formData == null || undefined) {
      formData = new FormData();
    }
    formData.append('contact_address', this.form.value.vendorEmail);
    formData.append('formtype_request_type_id', this.form.value.categories);
    formData.append('business_unit_business_unit_id', this.form.value.businessUnits);
    formData.append('vendor_name', this.form.value.firmName);
    formData.append('open_vendor', this.form.value.inCodex === true ? '1' : '0');
    formData.append('vendor_number', this.form.value.vendorNumber);
    formData.append('send_reminders', this.form.value.reminders === true ? '1' : '0');
    formData.append('field_id', this.form.value.fields);
    
    return formData;
  }

  /**
   * notifies parent formtype (category) has changed
   */
  onFormType() {
    this.formTypeEvent.next(`${this.form.value.categories}`);
  }

  /**
  * notifies parent field has changed
  */
  onField() {
    this.fieldEvent.next(`${this.form.value.fields}`);
  }

  hasField() {
    
    if (this.form.controls.categories.value !=  3 && this.form.controls.categories.value !=  4) {
      return true;
    }
    return false;
  }

}
