export class Certificate {
  id: number;
  tag: number;
  request_id: number;
  file_location: string;
  expire_date: string;   

  constructor(id: number, tag: number, request_id: number, file_location: string, expire_date: string) {
      this.id = id;
      this.tag = tag;
      this.request_id = request_id;
      this.file_location = file_location;
      this.expire_date = expire_date;
  }
}