import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  form : FormGroup;

  constructor(private loginService: LoginService, private fb: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    });
  }

  sendForgetPassword() {
   let formdata : FormData = new FormData();
   formdata.append("email", this.form.controls.email.value);
   this.loginService.forgotPassword(formdata).subscribe( 
     result => this.router.navigate(['']),
     error => this.router.navigate([''])
   );
  }

}
