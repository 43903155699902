import { Injectable } from '@angular/core';
import { User, UserGroup, UserCompany } from './user';
import { ResourceService } from '../resource.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ResourceService {
  activeUser: User
  activeUserGroups: [UserGroup]
  activeUserCompanies: [UserCompany]

  constructor(private http: HttpClient, private router: Router) {
    super(http, environment.identity.endpoint, "user")
  }

  getSessionInfo(): Observable<any> {
    return this.get("get_user" ,0, "GET_SESSION");    
  }

  /**
   * gets the current user
   * @returns a User
   */
  get currentUser() {
    return this.activeUser;  
  }
  
  /**
   * gets the current user groups
   * @returns a list of UserGroups
   */
  get currentUserGroups() {
    return this.activeUserGroups;
  }

  /**
   * gets the current user companies
   * @returns a list of UserCompanies
   */
  get currentUserCompanies() {
    return this.activeUserCompanies;
  }

  /**
   * Sets the current user
   * @param userInfo an Object containing the user data
   */
  userLoggedIn(userInfo):void {
    this.activeUser = userInfo[0];
    this.activeUserGroups = userInfo[1];
    this.activeUserCompanies = userInfo[2];
  }

  /**
   * sets the current user to null
   */
  userLoggedOut():void {
    this.activeUser = null;
    this.activeUserGroups = null;
    this.activeUserCompanies = null;

    this.get('log_out', 0, "LOG_OUT").subscribe( result => {
      this.router.navigate([""]);
    }, error => {this.router.navigate([""]);}
    )    
  }

  getCompanyEmail() {
    return this.get('get_company_email', 0, 'GET_COMPANY_EMAIL');
  }

  setCompanyEmail(user: FormData): Observable<any> {
    return this.post('set_company_email', 0, 'SET_COMPANY_EMAIL', user);
  }
}
