import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResourceService } from '../resource.service';



@Injectable({
  providedIn: 'root'
})
export class FormLoaderService extends ResourceService {
      
 constructor( http: HttpClient) {
    super(http, environment.identity.endpoint, "form");
  }

  public loadAnswers(id: number): Observable<any> {
    return this.get("get_answers", 0, "GET_ANSWERS", [], `requestid=${id}`); 
 }
  
  /**
   * Gets the form from the api
   */
  public loadForm(id: number): Observable<any> {
    return this.get("get_form", 0, "GET_FORM", [], `requestid=${id}`);
   } 
}