import { Component } from '@angular/core';
import { UserService } from './user/user.service';
import { Router } from '@angular/router';
import { UsergroupCheckService } from './shared/usergroup-check/usergroup-check.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'leveranciersPortaal';

  /**
   * Checks if logged in
   * @returns Boolean
   */
  

  constructor(private userService: UserService,
              private router: Router,
              private usergroupCheckService: UsergroupCheckService) {}

  isLoggedIn():boolean {
    if (this.userService.currentUser) {
      return true
    }
    return false
  }

  logOut():void {
    this.userService.userLoggedOut();
  }

  isAdmin(): boolean {
    return this.usergroupCheckService.checkIfAdmin();
  }

  isArchive(): boolean {
    return this.usergroupCheckService.checkIfArchive();
  }
}
