import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms'
import { LoginService } from './login.service';
import { UserService } from '../user.service';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * The LoginComponent
 */
@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  formError: boolean = false;
  formErrorMessage: string = ""

  vinciUser: string;
  vinciPassword: string;
  partnerUser: string;
  partnerPassword: string;


  constructor(private loginService: LoginService,
              private userService: UserService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  /**
   * Call that happens when an Http error occurs
   * @param error the error
   */            
  onHttpError(error: any):void {
    this.formError=true
    this.formErrorMessage = error.error.text
  }

  /**
   * call that happens when a form error occurs
   */
  onFormError():void {
    this.formError = true;
    this.formErrorMessage = "Alle velden moeten ingevuld worden."
  }

  /**
   * when a Vinci employee logs in
   * @param loginForm the login form
   */
  onVenedLogin() {
    let url: string;
    this.loginService.venedLogin().subscribe(
      result => {
        url = result;
        window.location.href = url
      },
      error => {
        console.log("error: ", error)
      }
    )
  }

  /**
   * when a Vinci partner logs in
   * @param loginForm The login form
   */
  onSubmitPartner(loginForm: NgForm) {
    if(loginForm && loginForm.valid) {
      this.loginService.authenticate(this.partnerUser, this.partnerPassword).subscribe(
        result => {
          this.userService.userLoggedIn(result);
          this.router.navigate(["/home"]);
        } ,
        error => this.onHttpError(error)
      )
    } else {
      this.onFormError()
    }
  }

  ngOnInit() {
    let userInfo = this.activatedRoute.snapshot.data["activeUser"] ;
    if(userInfo[0] != null) {
      this.userService.userLoggedIn(userInfo)
      this.router.navigate(["/home"])
    }
  }

}
