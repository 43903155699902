import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/user/user.service';


@Injectable({
  providedIn: 'root'
})
export class SelectGroupResolver implements Resolve<any> {

  constructor(private userService: UserService) { }

  resolve(){
    let data = this.userService.getCompanyEmail().pipe(
      map(result => (result)));
    return data;
  }
}
