import { Component, OnInit } from '@angular/core';
import { UserService } from '../user/user.service';
import { HomepageService } from './homepage.service';
import { UsergroupCheckService } from '../shared/usergroup-check/usergroup-check.service';

/**
 * The component for the homepage.
 */
@Component({
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  finishedRequests: string = "";
  startedRequests: string = ""; 
  openRequests: string = "";

  constructor(private userService: UserService,
              private homeService: HomepageService,
              private usergroupCheckService: UsergroupCheckService) { }

  /**
   * Checks if the user is in the group Request
   * @returns boolean
   */
  get isPlanner(): boolean {
    return this.usergroupCheckService.checkIfPlanner();
  }

  /**
   * Checks if the user is in the group Vendor
   * @returns boolean
   */
  get isVendor(): boolean {
    return this.usergroupCheckService.checkIfVendor();
  }

  /**
   * Checks if the user is in the group HSEQ
   * @returns boolean
   */
  get isHSEQ(): boolean {
    return this.usergroupCheckService.checkIfHSEQ();
  }

  /**
   * Checks if the user is in the group Purchase
   * @returns boolean
   */
  get isPurchase(): boolean {
   return this.usergroupCheckService.checkIfPurchase();
  }

  /**
   * Checks if the user is in the group Admin
   * @returns boolean
   */
  get isAdmin(): boolean {
    return this.usergroupCheckService.checkIfAdmin();
  }

  ngOnInit() {
    if(this.isAdmin){
      this.homeService.getStatistics().subscribe(
        result => {
          this.openRequests = result[0];
          this.finishedRequests = result[1];
          this.startedRequests = result[2];
        },
        error => {
          console.log("error: ", "Fout met statistieken ophalen.");
        }
      );
    }
  }
}
