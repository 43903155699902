export class AnswerOptions {
    answer_options_id: string;
    answers_answer_id: string;
    answer_option_id: string;

    constructor(answer_options_id: string, answers_answer_id: string, answer_option_id: string) {
        this.answer_options_id = answer_options_id
        this.answers_answer_id = answers_answer_id;
        this.answer_option_id = answer_option_id;
    }
}