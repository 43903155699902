import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Certificate } from './cetificate';
import { InfoRequest } from 'src/app/data/info-request';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DatePickerPopUpComponent } from 'src/app/shared/date-picker.popup/datepicker-popup.component';



@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {


  @Input('infoRequest') infoRequest: InfoRequest;
  @Input('certificateData') certificateData: Array<Certificate>;
  @ViewChild('datepicker', { static: false }) date: DatePickerPopUpComponent;

  downloadFileLink: string = environment.identity.endpoint + "services.php?controller=purchase&action=download_certificate_file"

  requestId: number;
  certificates: Array<Certificate> = [];
  certificatesToUpload: Array<Certificate> = [];
  files: Array<{ id: number, file: File }> = [];
  form: FormGroup;
  popUpOpen: boolean = false;





  constructor(private fb: FormBuilder, private route: ActivatedRoute) {
    this.form = this.fb.group({
      sncuAccept: this.fb.control(null, Validators.required),
      snaAccept: this.fb.control(null, Validators.required),
      waadiAccept: this.fb.control(null, Validators.required),
      sncu: this.fb.control(null,),
      sna: this.fb.control(null,),
      waadi: this.fb.control(null,)
    });
  }

  ngOnInit() {
    this.requestId = +this.infoRequest.request.request_id;
    if (this.certificateData) {
      this.certificates = this.certificateData;
    }

  }
  /**
   * fileupload event handler
   * @param event 
   * @param id 
   */
  addFile(event: any, id: number) {
    let file: File = event.target.files[0];
    this.certificatesToUpload = this.certificatesToUpload.filter((certificate) => certificate.tag != id);
    this.certificatesToUpload.push(new Certificate(null, id, this.requestId, file.name, null));
    this.files.filter((obj: { id: number, file: File }) => obj.id != id);
    this.files.push({ id, file });
    let fileName = file.name;
    let element = this.findElementLabel("" + id);
    element.innerText = fileName;
    //this.openPopUp(id);
  }

  /**
   * opens datepicker
   * @param id 
   */
  openPopUp(id: number) {
    this.date.setObjId(id);
    this.popUpOpen = true;
  }

  /**
   * After datepicked set date in obj
   * @param obj 
   */
  setDateHandler(obj: { id: number, date: any }) {
    this.popUpOpen = false;
    console.log(this.certificatesToUpload);
    let certificate: Certificate = this.certificatesToUpload.find((cert) => { return cert.tag == obj.id });
    this.certificatesToUpload = this.certificatesToUpload.filter((cert) => { return cert.tag != obj.id });
    certificate.expire_date = obj.date;
    this.certificatesToUpload.push(certificate);
    // console.log(this.certificatesToUpload);
    // console.log(this.files);
  }

  /**
  * find element label by 'label:' + id
  * @param elementId 
  */
  findElementLabel(elementId: string) {
    return this.findElement("label:" + elementId);
  }

  /**
   * find element by id
   * @param elementId 
   */
  findElement(elementId: string) {
    return document.getElementById(elementId);
  }

  getFileName(certificate: Certificate): string {
    if (certificate) {
      let parts: string[] = certificate.file_location.split("/")
      return parts[parts.length - 1];
    }
    return null;
  }

  getFileLocation(certificate: Certificate): string {
    if (certificate) {
      return certificate.file_location;
    }
    return null;
  }

  getCertificatesByTag(certificateTag: number) {
    return this.certificates.filter((certificate) => {
      return certificate.tag == certificateTag
    });
  }

  getFileById(certificateTag: number) {
    return this.files.find((obj: { id: number, file: File }) => {
      return obj.id === certificateTag
    });
  }

  addCertificateFiles(formData: FormData): FormData {
    this.certificatesToUpload.forEach(
      (obj: Certificate) => {
        formData.append((`certificate:${obj.tag}`), this.getFileById(obj.tag).file);
      }
    )
    return formData;
  }
}
