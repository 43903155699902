import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EditFormDialogComponent } from './edit-form-dialog/edit-form-dialog.component';
import { HomepageComponent } from './homepage/homepage.component';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './user/login/login.component';
import { ForgotPasswordComponent } from './user/login/forgot-password/forgot-password.component';
import { SelectGroupComponent } from './homepage/select-group/select-group.component';












@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    LoginComponent,
    EditFormDialogComponent,
    ForgotPasswordComponent,
    SelectGroupComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,

    NoopAnimationsModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [EditFormDialogComponent]
})
export class AppModule { }
