import { Injectable, Input } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormLoaderService } from './form-loader.service';


@Injectable(
    { providedIn: 'root' })
export class FormResolver implements Resolve<any>{



    constructor(private loadService: FormLoaderService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin(this.loadService.loadForm(route.params['id']).pipe(
            map(result1 => ({ form: result1 }))),
            this.loadService.loadAnswers(route.params['id']).pipe(
                map(result2 => ({ answers: result2 }))));
    }
}
