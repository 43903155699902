import { Component, Input, Output, EventEmitter } from '@angular/core'
import { openCloseAnimation, openCloseShadeAnimation } from './animations';

@Component({
    selector : 'date-picker-popup',
    styleUrls : ['./datepicker-popup.component.scss'],
    templateUrl: './datepicker-popup.component.html',
    animations: [
        openCloseAnimation,
        openCloseShadeAnimation,
      ]
})

export class DatePickerPopUpComponent{
    @Input() isOpen = false;
    @Output() onPickedDate: EventEmitter<any> = new EventEmitter<any>();
    @Input() pickedDate : any;

    obj: {id: any, date: any}

    setObjId(id: any) {
        this.obj = {id, date:null};
    }

    closePopUp(): void{  
        if(this.pickedDate){
        this.obj.date = this.pickedDate;
        this.onPickedDate.emit(this.obj);
        this.obj = {id:null,date:null};
        this.pickedDate = null;
        }
    }
}