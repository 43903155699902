
import { Answer } from './answer';

export class ReturnAnswer {
    request_id: string;
    form_id: string;
    answers: Array<Answer>;

    constructor(request_id: string, form_id: string, answers: Array<Answer>) {
        this.request_id = request_id;
        this.form_id = form_id;
        this.answers = answers;
    }


}