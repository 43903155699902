import { Injectable } from '@angular/core';
import { ResourceService } from '../resource.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class HomepageService extends ResourceService {

  constructor(public http: HttpClient,
              private userService: UserService) {
    super(http, environment.identity.endpoint, "home")
  }

  /**
   * a function that gets the statistics about the number of Requests
   * @returns Observable
   */
  getStatistics():Observable<any> {
    return this.post('home', 0, 'HOME', this.userService.currentUser)
  }
}
