import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { UserService } from '../user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginResolver implements Resolve<any> {

  constructor(private userService: UserService) { }

  resolve(){
    let data = this.userService.getSessionInfo().pipe(
      map(result => ( result)));
    return data
  }
}
