import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from '../../resource.service';
import { Observable } from 'rxjs';
import { format } from 'url';
import { environment } from 'src/environments/environment';

/**
 * This service is an extension of ResourceService. it connects to the login controller of the PHP backend
 */
@Injectable({
  providedIn: 'root'
})
export class LoginService extends ResourceService {

  constructor(public http: HttpClient) {
    super(http, environment.identity.endpoint, 'login')
  }

  /**
   * connects to the php backend and calls the vendor_login function
   * @param form the form containing the user credentials
   * @returns Observable
   */
  connect(form: FormData): Observable<any> {
    return this.post('vendor_login', 0, 'LOGIN', form);
  }

  /**
   * Creates formdata to send to the backend
   * @param login The email of the user
   * @param password the password of the user
   * @returns Observable
   */
  authenticate(login: string, password: string): Observable<any> {
    var form = new FormData();

    form.append('login', login);
    form.append('password', password);
    return this.connect(form);
  }

  venedLogin(): Observable<any> {
    return this.get('getVenedUrl', 0, 'GET_VENED_URL');
  }

  forgotPassword(email : FormData): Observable<any> {
    return this.post('post_forget_password', 0, 'POST_FORGET_PASSWORD', email)
  }

}
