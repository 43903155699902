import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Answer } from './form-object/answer/answer';
import { AnswerOptions } from './form-object/answer/answer-options';
import { AnswerText } from './form-object/answer/answer-text';
import { Form } from './form-object/question/form';
import { FormColumns } from './form-object/question/form.columns';
import { FormGroups } from './form-object/question/form.groups';
import { FormQuestions } from './form-object/question/form.questions';
import { FormRows } from './form-object/question/form.rows';
import { Question } from './form-object/question/question';
import { AnswerFiles } from './form-object/answer/answer-files';


@Injectable({
    providedIn: 'root'
})
export class FormBuilderService {

    answers: Array<Answer>;
    form: Form;


    constructor(private fb: FormBuilder) {
    }

    public buildForm(form: Form, answers: Array<Answer>): FormGroup {
        this.form = form;
        this.answers = answers;
        return this.toFormGroups(form);
    }


    /**
     * converts form object to formgroups/-arrays  so it can be used to create dynamic html 
     * @param form 
     */
    toFormGroups(form: Form): FormGroup {
        const myFormGroup = this.fb.group({
            form_id: this.fb.control(form.form_id),
            label: this.fb.control(form.label),
            formgroups: this.setFormGroups(form),
            fase: this.fb.control(form.fase),
            form_type_form_type_id: this.fb.control(form.form_type_form_type_id)
        });
        return myFormGroup;
    }

    /**
     * creates the (array of) fromgroups for MyForm
     * formgroups are the differnt sub forms such as quality and vinci-supliers
     * @param form 
     */
    setFormGroups(form: Form) {
        let array = this.fb.array([]);
        if (form.formgroups) {
            for (let i = 0; i < form.formgroups.length; i++) {
                array.push(this.fb.group({
                    formgroup_id: this.fb.control(form.formgroups[i].formgroup_id),
                    form_form_id: this.fb.control(form.formgroups[i].form_form_id),
                    title: this.fb.control(form.formgroups[i].title),
                    display_index: this.fb.control(form.formgroups[i].display_index),
                    background_color: this.fb.control(form.formgroups[i].background_color),
                    formrows: this.setFormRows(form.formgroups[i])
                }));
            };
            return array;
        }
    }

    /**
     * creates rows for text and input for each formogroup in 
     * @param formgroup 
     */
    setFormRows(formgroup: FormGroups) {
        let array = this.fb.array([]);
        if (formgroup.formrows) {
            for (let i = 0; i < formgroup.formrows.length; i++) {
                array.push(this.fb.group({
                    formrow_id: formgroup.formrows[i].formrow_id,
                    formgroup_formgroup_id: formgroup.formrows[i].formgroup_formgroup_id,
                    display_index: formgroup.formrows[i].display_index,
                    formcolumns: this.setFormColumns(formgroup.formrows[i])
                }));
            };
        }
        return array;
    }

    /**
     * Creates the columns that divide the page so two inputs can be create on the same line
     * @param formrow 
     */
    setFormColumns(formrow: FormRows) {
        let array = this.fb.array([]);
        if (formrow.formcolumns) {
            for (let i = 0; i < formrow.formcolumns.length; i++) {
                array.push(this.fb.group({
                    formcolumn_id: formrow.formcolumns[i].formcolumn_id,
                    formrow_formrow_id: formrow.formcolumns[i].formrow_formrow_id,
                    width: formrow.formcolumns[i].width,
                    formquestions: this.setFormQuestions(formrow.formcolumns[i]),
                }));
            }
        }
        return array;
    }

    /**
     * Creates the inputs with information
     * @param formcolumn 
     */
    setFormQuestions(formcolumn: FormColumns) {
        let array = this.fb.array([]);
        for (let i = 0; i < formcolumn.formquestions.length; i++) {
            array.push(this.fb.group({
                formquestion_id: formcolumn.formquestions[i].formquestion_id,
                formcolumn_formcolumn_id: formcolumn.formquestions[i].forcolumn_formcolumn_id,
                question_question_id: formcolumn.formquestions[i].question_question_id,
                display_index: formcolumn.formquestions[i].display_index,
                question: this.setQuestion(formcolumn.formquestions[i])
            }));
        }
        return array;
    }

    /**
     * Holds information to create the correct inputs
     * @param formquestion 
     */
    setQuestion(formquestion: FormQuestions) {

        let answer = this.answers != (undefined || null) ? this.getAnswer(formquestion.question) : null
        return this.fb.group({
            question_id: formquestion.question.question_id,
            description: formquestion.question.description,
            required: formquestion.question.required,
            length_answer_column: formquestion.question.length_answer_column,
            length_question_column: formquestion.question.length_question_column,
            question_type: formquestion.question.question_type,
            [`${formquestion.question.question_id}`]: this.setAnswerControlValue(answer, formquestion.question),
            options: this.setOptions(formquestion.question),
            has_experiation_date: <boolean>(this.setExperiationDate(formquestion.question))
        });
    }

    setExperiationDate(question: Question): any {
        if (question.has_experiation_date == 1) {
            return true;
        }
        return false;
    }

    /**
     * Find answer by question id
     * @param question
     */
    getAnswer(question: Question): Answer | Array<Answer> {
        if (question.question_type == "CheckList") {
            return this.answers.filter((answer) => {
                return answer.question_question_id === question.question_id;
            });

        } else {
            return this.answers.find((answer) => {
                return answer.question_question_id === question.question_id;
            });
        }
    }

    /**
     * sets answer value in form, with options for differnt input types.
     * 
     * @param answer 
     * @param question 
     */
    setAnswerControlValue(answer: Answer | Array<Answer>, question: Question) {
        if (answer != (null || undefined)) {
            switch (question.question_type) {
                case "FileType":
                    answer = <Answer>answer;
                    let answerFile = <AnswerFiles>answer.value;

                    return this.fb.control('', this.setValidation(question));

                case "RadioList":
                case "DropdownList":
                    answer = <Answer>answer
                    let type1: AnswerOptions = <AnswerOptions>answer.value;
                    return this.fb.control(`${type1.answer_option_id}`, this.setValidation(question));

                case "CheckList":
                    answer = <Array<Answer>>answer
                    let array = this.fb.array([]);
                    for (let i = 0; i < question.options.length; i++) {
                        array.push(this.fb.control(
                            this.getCheckboxValue(
                                question.options[i].question_option_id,
                                answer),
                            this.setValidation(question)))
                    }
                    return array

                case "LabelType":
                case "TextType":
                case "DateType":
                default:
                    answer = <Answer>answer
                    let type3: AnswerText = <AnswerText>answer.value;
                    return this.fb.control(type3.answer_text_value, this.setValidation(question));
            }
        } else if (question.question_type == "CheckList") {
            let array = this.fb.array([]);
            for (let i = 0; i < question.options.length; i++) {
                array.push(this.fb.control(false, this.setValidation(question)))
            }
            return array;
        }
        else {
            return this.fb.control('', this.setValidation(question));
        }
    }

    /**
     * converts array of boolean to array of (true) option_id
     * @param oId 
     * @param answerOptions 
     */
    getCheckboxValue(oId: string, answers: Answer[]): boolean {
        if (answers.find((answer) => {
            let answerOptions = <AnswerOptions>answer.value;
            return answerOptions.answer_option_id === oId;
        }) != (null || undefined)) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * adds validation for different input types
     * @param question 
     */
    setValidation(question: Question) {
        let array = [];
        switch (question.question_type) {
            case "TextType":

                break;
            case "RadioList":

                break;
            case "DropdownList":

                break;
            case "FileType":

                break;
            case "DateType":

                break;
            case "CheckList":

                break;
            case "LabelType":

                break;
        }

        if (question.required == "1") {
            array.push(Validators.required);
        }
        return array;
    }
    /**
     * 
     * @param question 
     */
    setOptions(question: Question): any {
        let array = this.fb.array([]);
        for (let i = 0; i < question.options.length; i++) {
            array.push(this.fb.group({
                questionoptionid: question.options[i].question_option_id,
                question_questionid: question.options[i].question_question_id,
                optionvaluetext: question.options[i].option_value_text
            }));
        }
        return array;
    }

    /**
     * A function that builds a formgroup surrounding the questions, for the question droplist
     * @param questions the questions that need to be incorporated in the droplist
     */
    buildQuestionForm(questions: [Question]): FormGroup {
        const myFormGroup = this.fb.group({
            formquestions: this.fb.array([])
        })
        const formArray = myFormGroup.get('formquestions') as FormArray;

        questions.forEach(question => {
            formArray.push(this.fb.group({
                formquestion_id: this.fb.control(''),
                formcolumn_formcolumn_id: this.fb.control(''),
                question_question_id: this.fb.control(question.question_id),
                display_index: this.fb.control(''),
                question: this.fb.group({
                    question_id: question.question_id,
                    description: question.description,
                    length_question_column: question.length_question_column,
                    length_answer_column: question.length_answer_column,
                    question_type: question.question_type,
                    options: this.setOptions(question),
                    required: this.fb.control(''),
                    [`${question.question_id}`]: this.setControlValue(question)
                })

            }))

        })
        return myFormGroup;
    }

    /**
     * a function that sets an empty formcontrol for a question identifier.
     * @param question the question for which the form control is made.
     */
    setControlValue(question: Question) {
        if (question.question_type == "CheckList") {
            let array = this.fb.array([]);
            for (let i = 0; i < question.options.length; i++) {
                array.push(this.fb.control(''))
            }
            return array;
        }
        else {
            return this.fb.control('');
        }
    }


    /**
     * Creates an Formrow section containing empty columns
     * @param colNumber The amount of columns
     * @param formGroupId The id of the formgroup it will belong to
     * @returns FormGroup
     */
    buildFormRowSection(colNumber: number, formGroupId: number): FormGroup {
        let id: number = Math.random() * 1000
        let rowSection: FormGroup
        rowSection = this.fb.group({
            formrow_id: 'temp:id',
            formgroup_formgroup_id: formGroupId,
            display_index: '',
            formcolumns: this.setColumnSection(colNumber)
        })

        return rowSection
    }

    /**
     * Creates an empty column section 
     * @param colNumber the amount of columns to create
     * @returns FormArray
     */
    setColumnSection(colNumber: number): FormArray {

        let array = this.fb.array([]);
        for (let i = 0; i < colNumber; i++) {
            let id: number = Math.random() * 1000
            array.push(this.fb.group({
                formcolumn_id: 'temp:' + id,
                formrow_formrow_id: '',
                width: 12 / colNumber,
                formquestions: this.fb.array([]),
            }));
        }
        return array;
    }


    /**
     * Creates an empty Formgroup section
     * @param formId The Id of the form it will belong to
     * @returns FormGroup 
     */
    buildFormGroupSection(formId: any): FormGroup {
        let id: number = Math.random() * 1000
        let formGroup: FormGroup = this.fb.group({
            formgroup_id: 'temp:' + id,
            form_form_id: formId,
            title: "Klik om tekst en Kleur aan te passen",
            display_index: '',
            background_color: 'blue',
            formrows: this.fb.array([])
        })

        return formGroup
    }
}