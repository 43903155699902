import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/user/user.service';
import { User } from 'src/app/user/user';
import { SelectGroupResolver } from './select-group.resolver.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MailingCompany } from './mailing-company';
import { Form } from 'src/app/form/form-object/question/form';

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.css']
})
export class SelectGroupComponent implements OnInit {

  form: FormGroup;
  companies: Array<MailingCompany>;
  user: User;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private userService: UserService, private router: Router) {
    this.companies = this.route.snapshot.data['data'];
    this.form = this.fb.group({
      email: this.fb.control('')
    })
  }

  ngOnInit() {
  }

  onSubmit() {
    this.user = this.userService.currentUser;
    this.user.email = this.setMailAdress();
    let formdata: FormData = new FormData();
    formdata.append( 'user_id', JSON.stringify(this.user.user_id));
    formdata.append( 'email', JSON.stringify(this.user.email));
    this.userService.setCompanyEmail(formdata).subscribe( 
      result => this.router.navigate(['home'])
    );
  }

  setMailAdress(): string {
    let email: string;
    email = this.user.username.split("@")[0];
    email = email + this.form.controls.email.value;
    console.log(email);
    return email;
  }

}
