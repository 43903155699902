import { AnswerText } from './answer-text';
import { AnswerFiles } from './answer-files';
import { AnswerOptions } from './answer-options';

export class Answer {
    answer_id: string
    question_question_id: string;
    request_request_id: string;
    value: AnswerText | AnswerFiles | AnswerOptions | Array<AnswerOptions>;

    constructor(answer_id: string, question_question_id: string, request_request_id: string,  value:  AnswerText | AnswerFiles | AnswerOptions | Array<AnswerOptions>) {
        this.answer_id = answer_id;
        this.request_request_id = request_request_id;
        this.question_question_id = question_question_id;
        this.value = value;
    }
}