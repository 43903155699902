import { HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

/**
 * A class that is meant to be extendend
 * It allows for Http requests to the PHP backend of leveranciersPortaal
 */
export class ResourceService {  
  constructor(
    public httpClient: HttpClient,
    private url: string,
    private controller: string,) {
  }

  /**
   * Post action
   * @param  {string} action Action that should be executed on the controller.
   * @param  {number} retryNumber Retries when the action fails.
   * @param  {string} errorCode Error code to be used when the action failed, this code is translated in human readable text.
   * @param  {any} item Postdata
   * @returns Observable
   */
  public post(action: string, retryNumber: number, errorCode: string, item: any): Observable<any> {
    return this.buildPipe(
      this.httpClient.post<any>(this.url + 'services.php?controller=' + this.controller + '&action=' + action, item),
      retryNumber,
      errorCode);
  }

  /**
   * Put action
   * @param  {string} action Action that should be executed on the controller.
   * @param  {number} retryNumber Retries when the action fails.
   * @param  {string} errorCode Error code to be used when the action failed, this code is translated in human readable text.
   * @param  {any} item Putdata
   * @returns Observable
   */
  public put(action: string, retryNumber: number, errorCode: string, item: any): Observable<any> {
    return this.buildPipe(
      this.httpClient.put<any>(this.url + 'services.php?controller=' + this.controller + '&action=' + action, item),
      retryNumber,
      errorCode);
  }

  /**
   * Get action
  * @param  {string} action Action that should be executed on the controller.
   * @param  {number} retryNumber Retries when the action fails.
   * @param  {string} errorCode Error code to be used when the action failed, this code is translated in human readable text.
   * @param  {string[]=[]} urlParams Get parameters 
   * @param  {string=null} queryParams Query parameters
   * @returns Observable
   */
  public get(action: string, retryNumber: number, errorCode: string, urlParams : string[] = [], queryParams : string = null ): Observable<any> {
      const params = new HttpParams({
        fromString: queryParams
      });

      return this.buildPipe(
      this.httpClient.get<any>(this.url + 'services.php?controller=' + this.controller + '&action=' + action  + urlParams.join('/'), {params : params}),
      retryNumber,
      errorCode);
  }

  /**
   * Delete action
  * @param  {string} action Action that should be executed on the controller.
   * @param  {number} retryNumber Retries when the action fails.
   * @param  {string} errorCode Error code to be used when the action failed, this code is translated in human readable text.
   * @param  {string[]=[]} urlParams
   * @param  {string=null} queryParams
   * @returns Observable
   */
  public delete(action: string, retryNumber: number, errorCode: string, urlParams : string[] = [], queryParams : string = null ): Observable<any> {
    const params = new HttpParams({
      fromString: queryParams
    });

    return this.buildPipe(
    this.httpClient.delete<any>(this.url + 'services.php?controller=' + this.controller + '&action=' + action + '/' +urlParams.join('/'), {params}),
    retryNumber,
    errorCode);
}

  /**
   * Returns a list
   * @param  {string} action Action that should be executed on the controller.
   * @param  {number} retryNumber Retries when the action fails.
   * @param  {string} errorCode Error code to be used when the action failed, this code is translated in human readable text.
   * @param  {string[]=[]} urlParams
   * @param  {string=null} queryParams
   */
  public list(action: string, retryNumber: number, errorCode: string, urlParams : string[] = [], queryParams : string = null ): Observable<any[]> {
    const params = new HttpParams({
      fromString: queryParams
    });
    
    let req : any = this.httpClient.get<any[]>(this.url + 'services.php?controller=' + this.controller + '&action=' + action + '/' +urlParams.join('/'), {params : params});
    return this.buildListPipe(
      req,
      retryNumber,
      errorCode);
  }

  
  /**
   * Patch action
   * @param  {string} action Action that should be executed on the controller.
   * @param  {number} retryNumber Retries when the action fails.
   * @param  {string} errorCode Error code to be used when the action failed, this code is translated in human readable text.
   * @param  {any} item Item to be updated
   * @returns Observable
   */
  public patch(action: string, retryNumber: number, errorCode: string, item: any): Observable<any> {
    return this.buildPipe(
      this.httpClient.patch<any>(this.url + 'services.php?controller=' + this.controller + '&action=' + action, item),
      retryNumber,
      errorCode);
  }

  /**
   * @param  {Observable<any[]>} request 
   * @param  {number} retryNumber retries when the action fails.
   * @param  {string} errorCode Error code to be parsed when the action fails.
   * @returns Observable
   */
  private buildListPipe(request: Observable<any[]>, retryNumber: number, errorCode: string): Observable<any[]> {
    return request.pipe(
      retry(retryNumber),
      map(data => {

        return data;
      }),
      catchError(err => {

        throw (err);
      }));
  }

  /**
   * Build pipe
   * @param  {Observable<any[]>} request 
   * @param  {number} retryNumber retries when the action fails.
   * @param  {string} errorCode Error code to be parsed when the action fails.
   * @returns Observable
   */
  private buildPipe(test: Observable<any>, retryNumber: number, errorCode: string): Observable<any> {
    return test.pipe(
      retry(retryNumber),
      map(data => {
        return data;
      }),
      catchError(err => {

        throw (err);
      }));
  }
}

