import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormResolver } from './form/form.resolver.service';
import { HomepageComponent } from './homepage/homepage.component';
import { NewUserGuard } from './homepage/select-group/new-user.guard';
import { SelectGroupComponent } from './homepage/select-group/select-group.component';
import { ForgotPasswordComponent } from './user/login/forgot-password/forgot-password.component';
import { LoginComponent } from './user/login/login.component';
import { LoginGuard } from './user/login/login.guard';
import { LoginResolver } from './user/login/login.resolver.service';
import { AdminGuard } from './user/usergroup-guards/admin-guard/admin.guard';
import { ArchiveGuard } from './user/usergroup-guards/archive-guard/archive.guard';
import { SelectGroupResolver } from './homepage/select-group/select-group.resolver.service';
import { LegalComponent } from './shared/information/legal/legal.component';
import { DisclaimerComponent } from './shared/information/disclaimer/disclaimer.component';
import { PrivacyComponent } from './shared/information/privacy/privacy.component';




const routes: Routes = [
  {path: '', component:LoginComponent, resolve: {activeUser: LoginResolver} },
  {path: 'password', component:ForgotPasswordComponent},
  {path: 'home', canActivate:[LoginGuard, NewUserGuard],  component: HomepageComponent},
  {path: 'select-email', component: SelectGroupComponent, resolve: {data: SelectGroupResolver}},
  {path: 'management',
   canActivate:[LoginGuard, AdminGuard],
   data: {preload: true},
   loadChildren: () =>
    import('./management/management.module').then(m => m.ManagementModule)},
  {path: 'archive',
   canActivate:[LoginGuard, ArchiveGuard],
   data: {preload: true},
   loadChildren: () => 
    import('./archive/archive.module').then(m => m.ArchiveModule)},
   {
    path: 'form',
    canActivate: [LoginGuard],
    data: { preload: true },
    loadChildren: () =>
      import('./form/form.module').then(m => m.FormModule)
  },
  { path: 'legal', component: LegalComponent},
  { path: 'disclaimer', component: DisclaimerComponent},
  { path: 'privacy', component: PrivacyComponent},
  { path: '**', redirectTo:''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [LoginResolver, FormResolver]
})
export class AppRoutingModule { }
