export class AnswerText {
    answer_text_id: string;
    answer_text_value: string;
    answer_answerid: string;
 


    constructor(answer_text_id: string,  answer_text_value: string,  answer_answerid: string) {
        this.answer_text_id = answer_text_id;
        this.answer_text_value = answer_text_value;
        this.answer_answerid = answer_answerid;
    }

}