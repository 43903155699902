
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ServerAnswer } from '../form-object/answer/server-answer';
import { Answer } from '../form-object/answer/answer';
import { ReturnAnswer } from '../form-object/answer/return-answer';
import { DatePickerPopUpComponent } from 'src/app/shared/date-picker.popup/datepicker-popup.component';
import { FormBuilderService } from '../form-builder.service';
import { AnswerFiles } from '../form-object/answer/answer-files';
import { AnswerOptions } from '../form-object/answer/answer-options';
import { AnswerText } from '../form-object/answer/answer-text';
import { Form } from '../form-object/question/form';




@Component({
  selector: 'app-field-form',
  templateUrl: './field-form.component.html',
  styleUrls: ['./field-form.component.css']
})
export class FieldFormComponent implements OnInit {




  downloadFileLink: string = environment.identity.endpoint + "services.php?controller=form&action=download_file"
  currentForm: FormGroup;
  serverAnswers: Array<ServerAnswer>;
  answers: Array<Answer>;
  formAnswer: ReturnAnswer;
  files: Array<Blob>;
  fileKeys: { key: any; value: number; }[];
  failedSubmit: boolean;
  requestId: string;
  hasForm: boolean;
  popUpOpen: boolean = false;


  @Input('fieldForm') fieldForm: Form;
  @Input('fieldAnswers') fieldAnswers: ServerAnswer[];

  @ViewChild('datepicker', { static: false }) date: DatePickerPopUpComponent;


  constructor(private activatedRoute: ActivatedRoute, private formBuilderService: FormBuilderService) {
  }

  ngOnInit() {
    this.requestId = this.activatedRoute.snapshot.params['id'];
    this.files = new Array<Blob>();
    this.fileKeys = [];
    this.failedSubmit = false;
    if (this.fieldForm) {
      this.setForm(this.fieldForm)
    }
  }

  ngAfterViewInit() { }



  setForm(form: Form) {
    if (this.fieldForm) {
      this.fieldForm = form;
    }
    this.answers = this.decodeAnswers(this.fieldAnswers);
    this.currentForm = this.formBuilderService.buildForm(this.fieldForm, this.answers);
    this.formAnswer = new ReturnAnswer(this.requestId, this.fieldForm.form_id, []);
    this.hasForm = true;
    this.ngAfterViewInit();
  }

  /**
   * Converts api data to objects (AnswerFiles/AnswerOption/AnswerText)
   * @param serverAnswers 
   */
  decodeAnswers(serverAnswers: ServerAnswer[]) {
    let answers: Array<Answer> = [];
    if (serverAnswers != null || undefined) {
      serverAnswers.forEach(element => {
        let value: AnswerFiles | AnswerOptions | AnswerText;
        if (element.answer_text_id != null || undefined) {
          value = new AnswerText(element.answer_text_id, element.answer_text_value, element.answer_answer_id);
        } else if (element.answer_options_id != null || undefined) {
          value = new AnswerOptions(element.answer_options_id, element.answer_answer_id, element.answer_option_id)
        } else if (element.answer_files_id != null || undefined) {
          value = new AnswerFiles(element.answer_option_id, element.file_location, element.experiation_date, element.answer_answer_id);
        }
        answers.push(new Answer(element.answer_id, element.question_question_id, element.request_request_id, value));
      });
    }
    return answers;

  }

  /**
 * gets formcontrol so the input in html can bind to it.
 * @param question 
 * @param questionId 
 */
  getFormcontrol(question: FormGroup, questionId: number): FormControl {
    return <FormControl>question.controls[questionId];
  }

  /**
   * checks if form is valid
   */
  public getFailedSubmit(): boolean {
    return this.failedSubmit;
  }


  /**
   * saves form wether it's valid or not  
   * @param action 
   */
  onSave() {
    return this.buildAnswerData();
  }

  buildFieldData(answerForm: FormData): FormData {
    answerForm.append("field_answer", JSON.stringify(this.formAnswer));
    answerForm.append('field_keys', JSON.stringify(this.fileKeys));
    answerForm = this.buildFieldFileData(answerForm);
    return answerForm;
  }

  /**
   * Adds all files to answerForm to send to the api.
   * Files are indexed by "file:'index'"  
   * @param answerForm 
   */
  buildFieldFileData(answerForm: FormData) {
    for (let index = 0; index < this.files.length; index++) {
      const file = this.files[index];
      answerForm.append((`field_file:${index}`), file);
    }
    return answerForm;
  }



  /**
   * creates a new answer for the question because non exist.
   * @param question 
   */
  createAnswer(question: FormGroup) {
    switch (question.value.question_type) {
      case "FileType":
        this.formAnswer.answers.push(new Answer(null, question.value.question_id, this.formAnswer.request_id,
          new AnswerFiles(null, question.value[question.value.question_id], null, null)));
        if (question.value.has_experiation_date) {
          this.openPopUp(question);
        }
        break;

      case "RadioList":
      case "DropdownList":
        this.formAnswer.answers.push(new Answer(null, question.value.question_id, this.formAnswer.request_id,
          new AnswerOptions(null, null, question.controls[question.value.question_id].value)));
        break;

      case "CheckList":
        let array: FormArray = <FormArray>question.controls[question.value.question_id];
        let options: Array<AnswerOptions> = [];
        for (let index = 0; index < array.length; index++) {
          if (array.controls[index].value) {
            const availableOptions: FormArray = <FormArray>question.controls.options;
            const option: FormGroup = <FormGroup>availableOptions.controls[index];
            options.push(new AnswerOptions(null, null, option.value.questionoptionid));
          }
        }
        this.formAnswer.answers.push(new Answer(null, question.value.question_id, this.formAnswer.request_id, options));
        break;

      case "LabelType":
      case "TextType":
      case "DateType":
      default:
        this.formAnswer.answers.push(new Answer(null, question.value.question_id, null,
          new AnswerText(null, question.controls[question.value.question_id].value, null)));
        break;
    }
  }



  /**
   *  updates the answer of a question with a new value.
   * @param answer 
   * @param question 
   */
  updateAnswer(answer: Answer, question: FormGroup) {
    switch (question.value.question_type) {
      case "FileType":
        let answerFile: AnswerFiles = <AnswerFiles>answer.value;
        answerFile.file_location = question.value[question.value.question_id];
        if (question.value.has_experiation_date) {
          this.openPopUp(question);
        }
        break;

      case "RadioList":
      case "DropdownList":
        let answerOption: AnswerOptions = <AnswerOptions>answer.value;
        answerOption.answer_option_id = question.controls[question.value.question_id].value;
        break;

      case "CheckList":
        let array: FormArray = <FormArray>question.controls[question.value.question_id];
        let options: Array<AnswerOptions> = [];
        let answerOptions: Array<AnswerOptions> = <Array<AnswerOptions>>answer.value;

        for (let index = 0; index < array.length; index++) {
          if (array.controls[index].value) {
            const availableOptions: FormArray = <FormArray>question.controls.options;
            const option: FormGroup = <FormGroup>availableOptions.controls[index];

            const foundOption = this.findOption(answerOptions, option.value.questionoptionid);
            if (foundOption == undefined || null) {
              options.push(new AnswerOptions(null, answer.answer_id, option.value.questionoptionid));
            } else {
              options.push(foundOption);
            }
          }
        }
        answer.value = options;

        break;
      case "LabelType":
      case "TextType":
      case "DateType":
      default:
        let value = <AnswerText>answer.value;
        value.answer_text_value = question.controls[question.value.question_id].value;
        answer.value = value;
        break;
    }
  }

  openPopUp(question: FormGroup) {
    this.date.setObjId(question.value.question_id);
    this.popUpOpen = true;
  }

  /**
   * help function for updateAnswer() to save the correct options
   * @param options 
   * @param optionId 
   */
  private findOption(options: Array<AnswerOptions>, optionId: string) {
    return options.find(option => {
      return option.answer_option_id === optionId;
    })
  }

  /**
   * finds answer in formAnswer.answers with question id
   * @param questionId 
   */
  findAnswer(questionId: string): Answer {
    let answers = this.formAnswer.answers;
    return answers.find(answer => {
      return answer.question_question_id === questionId;
    })
  }



  /**
   * builds the answer data which is send to the PHP API based on the form values
   */
  buildAnswerData(): FormData {
    var answerForm = new FormData();
    answerForm.append("answer", JSON.stringify(this.formAnswer));
    answerForm.append('keys', JSON.stringify(this.fileKeys));
    answerForm = this.buildFileData(answerForm);
    return answerForm;
  }

  /**
   * Adds all files to answerForm to send to the api.
   * Files are indexed by "file:'index'"  
   * @param answerForm 
   */
  buildFileData(answerForm: FormData) {
    for (let index = 0; index < this.files.length; index++) {
      const file = this.files[index];
      answerForm.append((`file:${index}`), file);
    }
    return answerForm;
  }


  /**
   * update answer / create answer 
   * @param event 
   * @param question 
   */
  answerChangeHandler(event: any, question: FormGroup) {
    let answer = this.findAnswer(question.value.question_id);
    if (answer != null) {
      this.updateAnswer(answer, question);
    } else if (question.controls[question.value.question_id] != undefined) {
      this.createAnswer(question);
    }
  }

  /**
   * change input file text to file name. 
   * Adds file to this.files to be uploaded to api.
   * Maps file index with question_id.
   * @param event 
   * @param elementId 
   */
  fileChangeHandler(event: any, question: FormGroup) {
    let file: File = event.target.files[0];
    let fileName = file.name;
    let element = this.findElementLabel(question.value.question_id);
    element.innerText = fileName;
    let answer = this.findAnswer(question.value.question_id);
    if (answer != null) {
      this.updateAnswer(answer, question);
      this.fileKeys = this.fileKeys.filter(key => key.key != question.value.question_id);
    } else {
      this.createAnswer(question);
    }
    this.files.push(file);
    this.fileKeys.push({ key: question.value.question_id, value: this.files.length - 1 });
  }


  /**
   * find element label by 'label:' + id
   * @param elementId 
   */
  findElementLabel(elementId: string) {
    return this.findElement("label:" + elementId);
  }

  /**
   * find element by id
   * @param elementId 
   */
  findElement(elementId: string) {
    return document.getElementById(elementId);
  }


  getFileLocation(questionId: string) {
    let answer = this.findServerAnswer(questionId);
    if (answer != (null || undefined)) {
      let answerFile = <AnswerFiles>answer.value;
      if (answerFile != (null || undefined)) {
        if (answerFile.file_location != (null || undefined)) {
          let parts: string[] = answerFile.file_location.split("/");
          return parts[parts.length - 1];
        }
      }
    }
    return '';
  }

  getFileAnswerDate(questionId: string) {
    let answer = this.findServerAnswer(questionId);
    if (answer != (null || undefined)) {
      let answerFile = <AnswerFiles>answer.value;
      if (answerFile != (null || undefined)) {
        return answerFile.experiation_date;
      }
    }
    return '';
  }

  // getNewFileAnswerDate(questionId: string) {
  //  let answer = this.formAnswer.answers.find(answer => {
  //     return answer.question_question_id === questionId;
  //   })
  //   if (answer != (null || undefined)) {
  //     let answerFile = <AnswerFiles>answer.value;
  //     if (answerFile != (null || undefined)) {
  //       return answerFile.experiation_date;
  //     }
  //   }
  //   return '';
  // }

  findServerAnswer(questionId: string): Answer {
    return this.answers.find(answer => {
      return answer.question_question_id === questionId;
    })
  }

  getFileAnswerPath(questionId: string) {
    let answer = this.findServerAnswer(questionId);
    if (answer) {
      let answerFile = <AnswerFiles>answer.value;
      return answerFile.file_location;
    }
    return '';
  }

  setDateHandler(obj: { id: string, date: any }) {
    this.popUpOpen = false;
    let answer: Answer = this.findAnswer(obj.id);


    this.formAnswer.answers = this.formAnswer.answers.filter(answer => {
      return answer.question_question_id != obj.id;
    })

    let answerFile: AnswerFiles = <AnswerFiles>answer.value;
    answerFile.experiation_date = obj.date;
    answer.value = answerFile;

    this.formAnswer.answers.push(answer);
  }

}
