export class AnswerFiles {
    answer_files_id: string;
    file_location: string;
    experiation_date: string;
    answers_answer_id: string;


    constructor(answer_files_id: string, file_location: string, experiation_date: string, answers_answer_id: string) {
        this.answer_files_id = answer_files_id;
        this.file_location = file_location;
        this.experiation_date = experiation_date;
        this.answers_answer_id = answers_answer_id;

    }
}