
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormBuilderService } from './form-builder.service';
import { Answer } from './form-object/answer/answer';
import { AnswerFiles } from './form-object/answer/answer-files';
import { AnswerOptions } from './form-object/answer/answer-options';
import { AnswerText } from './form-object/answer/answer-text';
import { ReturnAnswer } from './form-object/answer/return-answer';
import { ServerAnswer } from './form-object/answer/server-answer';
import { Form } from './form-object/question/form';
import { environment } from 'src/environments/environment';
import { DatePickerPopUpComponent } from '../shared/date-picker.popup/datepicker-popup.component';




@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {




  downloadFileLink: string = environment.identity.endpoint + "services.php?controller=form&action=download_file"
  currentForm: FormGroup;
  serverAnswers: Array<ServerAnswer>;
  answers: Array<Answer>;
  formAnswer: ReturnAnswer;
  files: Array<Blob>;
  deleteFiles: Array<AnswerFiles> = [];
  fileKeys: { key: any; value: number; }[];
  failedSubmit: boolean;
  requestId: string;
  hasForm: boolean;
  popUpOpen: boolean = false;


  @Input('initForm') initForm: Form;
  @Input('initAnswers') initAnswers: ServerAnswer[];

  @ViewChild('datepicker', { static: false }) date: DatePickerPopUpComponent;


  constructor(private activatedRoute: ActivatedRoute, private formBuilderService: FormBuilderService) {
  }

  ngOnInit() {
    this.requestId = this.activatedRoute.snapshot.params['id'];
    this.files = new Array<Blob>();
    this.fileKeys = [];
    this.failedSubmit = false;
    this.hasForm = false;
    if (this.initForm) {
      this.setForm(this.initForm)
    }

  }

  ngAfterViewInit() {

  }

  setForm(form: Form) {
    this.initForm = form;
    this.answers = this.decodeAnswers(this.initAnswers);
    this.currentForm = this.formBuilderService.buildForm(this.initForm, this.answers);
    this.formAnswer = new ReturnAnswer(this.requestId, this.initForm.form_id, []);
    this.hasForm = true;
  }

  /**
   * Converts api data to objects (AnswerFiles/AnswerOption/AnswerText)
   * @param serverAnswers 
   */
  decodeAnswers(serverAnswers: ServerAnswer[]) {
    let answers: Array<Answer> = [];
    if (serverAnswers != null || undefined) {
      serverAnswers.forEach(element => {
        let value: AnswerFiles | AnswerOptions | AnswerText;
        if (element.answer_text_id != null || undefined) {
          value = new AnswerText(element.answer_text_id, element.answer_text_value, element.answer_answer_id);
        } else if (element.answer_options_id != null || undefined) {
          value = new AnswerOptions(element.answer_options_id, element.answer_answer_id, element.answer_option_id)
        } else if (element.answer_files_id != null || undefined) {
          value = new AnswerFiles(element.answer_option_id, element.file_location, element.experiation_date, element.answer_answer_id);
        }
        answers.push(new Answer(element.answer_id, element.question_question_id, element.request_request_id, value));
      });
    }
    return answers;

  }

  /**
 * gets formcontrol so the input in html can bind to it.
 * @param question 
 * @param questionId 
 */
  getFormcontrol(question: FormGroup, questionId: number): FormControl {
    return <FormControl>question.controls[questionId];
  }

  /**
   * checks if form is valid
   */
  public getFailedSubmit(): boolean {
    return this.failedSubmit;
  }


  /**
   * saves form wether it's valid or not  
   * @param action 
   */
  onSave() {
    return this.buildAnswerData();
  }

  buildFieldData(answerForm: FormData): FormData {
    answerForm.append("field_answer", JSON.stringify(this.formAnswer));
    answerForm.append('field_keys', JSON.stringify(this.fileKeys));
    answerForm = this.buildFieldFileData(answerForm);
    return answerForm;
  }

  /**
   * Adds all files to answerForm to send to the api.
   * Files are indexed by "file:'index'"  
   * @param answerForm 
   */
  buildFieldFileData(answerForm: FormData) {
    for (let index = 0; index < this.files.length; index++) {
      const file = this.files[index];
      answerForm.append((`field_file:${index}`), file);
    }
    return answerForm;
  }



  /**
   * creates a new answer for the question because non exist.
   * @param question 
   */
  createAnswer(question: FormGroup) {
    switch (question.value.question_type) {
      case "FileType":
        this.formAnswer.answers.push(new Answer(null, question.value.question_id, this.formAnswer.request_id,
          new AnswerFiles(null, question.value[question.value.question_id], null, null)));
        if (question.value.has_experiation_date) {
          this.openPopUp(question);
        }
        break;

      case "RadioList":
      case "DropdownList":
        this.formAnswer.answers.push(new Answer(null, question.value.question_id, this.formAnswer.request_id,
          new AnswerOptions(null, null, question.controls[question.value.question_id].value)));
        break;

      case "CheckList":
        let array: FormArray = <FormArray>question.controls[question.value.question_id];
        let options: Array<AnswerOptions> = [];
        for (let index = 0; index < array.length; index++) {
          if (array.controls[index].value) {
            const availableOptions: FormArray = <FormArray>question.controls.options;
            const option: FormGroup = <FormGroup>availableOptions.controls[index];
            options.push(new AnswerOptions(null, null, option.value.questionoptionid));
          }
        }
        this.formAnswer.answers.push(new Answer(null, question.value.question_id, this.formAnswer.request_id, options));
        break;

      case "LabelType":
      case "TextType":
      case "DateType":
      default:
        this.formAnswer.answers.push(new Answer(null, question.value.question_id, null,
          new AnswerText(null, question.controls[question.value.question_id].value, null)));
        break;
    }
  }



  /**
   *  updates the answer of a question with a new value.
   * @param answer 
   * @param question 
   */
  updateAnswer(answer: Answer, question: FormGroup) {
    switch (question.value.question_type) {
      case "FileType":
        let answerFile: AnswerFiles = <AnswerFiles>answer.value;
        answerFile.file_location = question.value[question.value.question_id];
        if (question.value.has_experiation_date) {
          this.openPopUp(question);
        }
        break;

      case "RadioList":
      case "DropdownList":
        let answerOption: AnswerOptions = <AnswerOptions>answer.value;
        answerOption.answer_option_id = question.controls[question.value.question_id].value;
        break;

      case "CheckList":
        let array: FormArray = <FormArray>question.controls[question.value.question_id];
        let options: Array<AnswerOptions> = [];
        let answerOptions: Array<AnswerOptions> = <Array<AnswerOptions>>answer.value;

        for (let index = 0; index < array.length; index++) {
          if (array.controls[index].value) {
            const availableOptions: FormArray = <FormArray>question.controls.options;
            const option: FormGroup = <FormGroup>availableOptions.controls[index];

            const foundOption = this.findOption(answerOptions, option.value.questionoptionid);
            if (foundOption == undefined || null) {
              options.push(new AnswerOptions(null, answer.answer_id, option.value.questionoptionid));
            } else {
              options.push(foundOption);
            }
          }
        }
        answer.value = options;

        break;
      case "LabelType":
      case "TextType":
      case "DateType":
      default:
        let value = <AnswerText>answer.value;
        value.answer_text_value = question.controls[question.value.question_id].value;
        answer.value = value;
        break;
    }
  }

  openPopUp(question: FormGroup) {
    this.date.setObjId(question.value.question_id);
    this.popUpOpen = true;
  }

  /**
   * help function for updateAnswer() to save the correct options
   * @param options 
   * @param optionId 
   */
  private findOption(options: Array<AnswerOptions>, optionId: string) {
    return options.find(option => {
      return option.answer_option_id === optionId;
    })
  }

  /**
   * finds answer in formAnswer.answers with question id
   * @param questionId 
   */
  findAnswer(questionId: string): Answer {
    let answers = this.formAnswer.answers;
    return answers.find(answer => {
      return answer.question_question_id === questionId;
    })
  }



  /**
   * builds the answer data which is send to the PHP API based on the form values
   */
  buildAnswerData(): FormData {
    var answerForm = new FormData();
    answerForm.append("answer", JSON.stringify(this.formAnswer));
    answerForm.append('keys', JSON.stringify(this.fileKeys));
    answerForm = this.buildFileData(answerForm);
    answerForm.append("delete_files", JSON.stringify(this.deleteFiles));
    return answerForm;
  }


  /**
   * Adds all files to answerForm to send to the api.
   * Files are indexed by "file:'index'"  
   * @param answerForm 
   */
  buildFileData(answerForm: FormData) {
    for (let index = 0; index < this.files.length; index++) {
      const file = this.files[index];
      answerForm.append((`file:${index}`), file);
    }
    return answerForm;
  }




  /**
   * update answer / create answer 
   * @param event 
   * @param question 
   */
  answerChangeHandler(event: any, question: FormGroup) {
    let answer = this.findAnswer(question.value.question_id);
    if (answer != null) {
      this.updateAnswer(answer, question);
    } else if (question.controls[question.value.question_id] != undefined) {
      this.createAnswer(question);
    }
  }

  /**
   * change input file text to file name. 
   * Adds file to this.files to be uploaded to api.
   * Maps file index with question_id.
   * @param event 
   * @param elementId 
   */
  fileChangeHandler(event: any, question: FormGroup) {
    let file: File = event.target.files[0];
    let fileName = file.name;
    let element = this.findElementLabel(question.value.question_id);
    element.innerText = fileName;
    let answer = this.findAnswer(question.value.question_id);
    if (answer != null) {
      this.updateAnswer(answer, question);
      this.fileKeys = this.fileKeys.filter(key => key.key != question.value.question_id);
    } else {
      this.createAnswer(question);
    }
    this.files.push(file);
    this.fileKeys.push({ key: question.value.question_id, value: this.files.length - 1 });
  }


  /**
   * find element label by 'label:' + id
   * @param elementId 
   */
  findElementLabel(elementId: string) {
    return this.findElement("label:" + elementId);
  }

  /**
   * find element by id
   * @param elementId 
   */
  findElement(elementId: string) {
    return document.getElementById(elementId);
  }


  getFileLocation(location: string) {
    let parts: string[] = location.split("/");
    return parts[parts.length - 1];
  }

  // getFileAnswerDate(questionId:string) {
  //   let answer = this.findServerAnswer(questionId);
  //   if (answer != (null || undefined)) {
  //     let answerFile = <AnswerFiles>answer.value;
  //     if (answerFile != (null || undefined)) {
  //       return answerFile.experiation_date;
  //     }
  //   }
  //   return '';
  // }


/**
 * gets answer with questionId
 * @param questionId 
 */
  findServerAnswer(questionId: string): Answer {
    return this.answers.find(answer => {
      return answer.question_question_id === questionId;
    })
  }

  /**
   * gets the file(s) tied to questionId
   * @param questionId 
   */
  getExsitingFiles(questionId: string) {
    let answers = this.answers.filter(answer => {
      return answer.question_question_id === questionId;
    })

    let files: AnswerFiles[] = [];
    answers.forEach(answer => {
      files.push(<AnswerFiles>answer.value);
    });
    return files;
  }

  /**
   * sets up file to be deleted when saved
   * @param file 
   */
  deleteFile(file: AnswerFiles) {
    this.deleteFiles.push(file);
    let element = this.findElement("file:" + file.file_location);
    element.hidden = true;
  }

/**
 * sets color for text depending on date ahs passed.
 * @param value 
 */
  checkDateColor(value: string) {
    let oldDate = new Date(value);
    if (this.checkDateValid(oldDate)) {
      return 'white';
    }
    return 'red';
  }

  /**
   * checks if date has passed.
   * @param value 
   */
  checkDateValid(value: Date) {
    let now = new Date();
    return (value > now);
  }

    /**
     * opens the date pop up and handles the date filled in.
     * @param obj 
     */
  setDateHandler(obj: { id: string, date: any }) {
    this.popUpOpen = false;
    let answer: Answer = this.findAnswer(obj.id);


    this.formAnswer.answers = this.formAnswer.answers.filter(answer => {
      return answer.question_question_id != obj.id;
    })

    let answerFile: AnswerFiles = <AnswerFiles>answer.value;
    answerFile.experiation_date = obj.date;
    answer.value = answerFile;

    this.formAnswer.answers.push(answer);
  }

  codeString(url: string){
    return this.encodeURIComponent(url);
  }

  /**
   * encode for special characters so the url works
   * @param str 
   */
  encodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*&]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}

}
