import { Component, OnInit, Injectable } from '@angular/core';
import { UserService } from 'src/app/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class UsergroupCheckService implements OnInit {
  constructor(private userService: UserService) {

  }

  ngOnInit(): void {

  }

  
  checkIfArchive(): boolean {
    if (this.userService.currentUserGroups) {
      const groups = this.userService.currentUserGroups;
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].group_id == "Archive") {
          return true;
        }
      }
    }
    return false
  }

  checkIfVendor(): boolean {
    if (this.userService.currentUserGroups) {
      const groups = this.userService.currentUserGroups;
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].group_id == "Leverancier") {
          return true;
        }
      }
    }
    return false;
  }

  checkIfPlanner(): boolean {
    if (this.userService.currentUserGroups) {
      const groups = this.userService.currentUserGroups;
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].group_id == "NieuweAanvraag") {
          return true;
        }
      }
    }
    return false
  }

  checkIfAdmin(): boolean {
    if (this.userService.currentUserGroups) {
      const groups = this.userService.currentUserGroups;
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].group_id == "Admin") {
          return true;
        }
      }
    }
    return false;
  }

  checkIfHSEQ(): boolean {
    if (this.userService.currentUserGroups) {
      const groups = this.userService.currentUserGroups;
      for (let i = 0; i < groups.length; i++) {
        if (groups[i]['group_id'] == "HSEQ") {
          return true
        }
      }
    }
    return false
  }

  checkIfPurchase(): boolean {
    if (this.userService.currentUserGroups) {
      const groups = this.userService.currentUserGroups;
      for (let i = 0; i < groups.length; i++) {
        if (groups[i]['group_id'] == "LeverancierControleren") {
          return true
        }
      }
    }
    return false
  }

}
